












import { PageCategory } from '~/types/PageCategory'

export default {
  name: 'ProductSuggestionActions',
  props: {
    action: {
      required: true,
      type: Object,
    },
    wrapMobile: {
      default: false,
      type: Boolean,
    },
  },
  setup () {
    return {
      PageCategory,
    }
  },
}
