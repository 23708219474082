enum RendererTypesEnum {
  RADIO = 'RadioType',
  CHECKBOX = 'CheckboxType',
  SWATCH_COLOR = 'SwatchColorType',
  YES_NO = 'YesNoType',
  DEFAULT = 'CheckboxType',
  YESONLYCHECKBOX = 'YesOnlyCheckboxType',
  
}

export default RendererTypesEnum;
