











































































import {SfSelect, SfLink, SfPagination, SfIcon} from '@storefront-ui/vue'
import DsbPagination from "~/components/DsbPagination.vue";
import { PageCategory } from "~/types/PageCategory";
import { ListFooter } from "~/types/ListCategories";
import {useRoute, useRouter, defineComponent, computed} from "@nuxtjs/composition-api";

export default defineComponent({
  name: "DsbProductList",
  components: {
    SfSelect,
    SfLink,
    DsbPagination,
    SfPagination,
    SfIcon,
  },
  props: {
    paginationProp: {
      type: Object
    },
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    function updateRoute(page) {
      router.replace({...route, query: {...route.value.query, itemsPerPage: page, page: '1'}})
    }

    const pagination = computed(() => props.paginationProp)

    const paginationSelection = computed<string>(() => {
      if(pagination.value?.itemsPerPage === undefined){
        return ''
      }
      return pagination.value?.itemsPerPage.toString()
    });


    return {
      updateRoute,
      PageCategory,
      ListFooter,
      pagination,
      paginationSelection
    }
  }
})
