





























































import { SfIcon, SfLoader } from '@storefront-ui/vue'
import { PageCategory } from '~/types/PageCategory'
import { SearchResults } from '~/types/search/SearchCategories'
import ProductSuggestion from './ProductSuggestion.vue'
import {computed, defineComponent} from '@nuxtjs/composition-api'
import productGetters, {getProductThumbnailImage} from "~/modules/catalog/product/getters/productGetters";

export default defineComponent ({
  name: 'SearchResults',
  props: {
    autocomplete: {
      type: Array,
    },
    searchHistory: {
      type: Array,
    },
    suggestions: {
      type: Array,
    },
    tempSearchTerm: {
      type: String,
    },
  },
  components: {
    ProductSuggestion,
    SfIcon,
    SfLoader
  },
  setup(props, {emit}) {
    const suggestionsResults = computed(() => {
      return props.suggestions
    })

    const closeSearchResults = () => {
      emit('closeSearchResults')
    }

    const search = () => {
      emit('search')
    }

    return {
      suggestionsResults,
      isAddToCartVisible: computed(() => (suggestionsResults.value.length === 1)),
      autocompleteResults: computed(() => props.autocomplete),
      searchHistoryResults: computed(() => props.searchHistory),
      tempSearchInput: computed(() => props.tempSearchTerm),
      PageCategory,
      SearchResults,
      search,
      getProductThumbnailImage,
      closeSearchResults,
      productGetters
    }
  },
})
