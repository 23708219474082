<template>
  <nav class="sf-pagination" aria-label="Seitennavigation">
    <!-- @slot Custom markup for previous page button -->
    <slot v-if="hasArrows" name="prev" v-bind="{ isDisabled: !canGoPrev, getLinkTo, prev: getPrev, goPrev: () => currentPage = getPrev }">
      <div class="sf-pagination__item prev">
        <component
          :is="componentIs"
          :class="{
            'sf-button--pure': !hasRouter,
            'sf-arrow--transparent': !hasRouter && !canGoPrev,
          }"
          :link="hasRouter && getLinkTo(getPrev)"
          :disabled="!canGoPrev"
          aria-label="Go to previous page"
          @click="!hasRouter && (currentPage = getPrev)"
        >
          <SfIcon icon="arrow_left" size="1.125rem" />
        </component>
      </div>
    </slot>
    <template v-if="showFirst">
      <slot name="number" v-bind="{ page: 1, getLinkTo, goFirst: () => currentPage = 1 }">
        <component
          :is="componentIs"
          class="sf-pagination__item"
          :class="{
            'sf-button--pure': !hasRouter,
          }"
          :link="hasRouter && getLinkTo(1)"
          @click="!hasRouter && (currentPage = 1)"
        >
          1
        </component>
      </slot>
      <slot v-if="firstVisiblePageNumber > 2" name="points">
        <div class="sf-pagination__item">
          ...
        </div>
      </slot>
    </template>
    <template v-for="page in limitedPageNumbers">
      <slot name="number" v-bind="{ page, getLinkTo, goPage: () => currentPage = page }">
        <component
          :is="currentPage === page ? 'span' : componentIs"
          :key="page"
          class="sf-pagination__item"
          :class="{
            'sf-button--pure': !hasRouter && currentPage !== page,
            current: currentPage === page,
          }"
          :link="hasRouter && currentPage !== page && getLinkTo(page)"
          @click="!hasRouter && currentPage !== page && (currentPage = page)"
        >
          {{ page }}
        </component>
      </slot>
    </template>
    <template v-if="showLast">
      <slot v-if="lastVisiblePageNumber < total - 1" name="points">
        <div class="sf-pagination__item">
          ...
        </div>
      </slot>
      <slot name="number" v-bind="{ page: total, getLinkTo, goLast: () => currentPage = total }">
        <component
          :is="componentIs"
          class="sf-pagination__item"
          :class="{
            'sf-button--pure': !hasRouter,
          }"
          :link="hasRouter && getLinkTo(total)"
          @click="!hasRouter && (currentPage = total)"
        >
          {{ total }}
        </component>
      </slot>
    </template>
    <!-- @slot Custom markup for previous page button -->
    <slot v-if="hasArrows" name="next" v-bind="{ isDisabled: !canGoNext, getLinkTo, next: getNext, goNext: () => currentPage = getNext }">
      <div class="sf-pagination__item next">
        <component
          :is="componentIs"
          :class="{
            'sf-button--pure': !hasRouter,
            'sf-arrow--transparent': !hasRouter && !canGoNext,
          }"
          :link="hasRouter && getLinkTo(getNext)"
          :disabled="!canGoNext"
          aria-label="Go to previous next"
          @click="!hasRouter && (currentPage = getNext)"
        >
          <SfIcon icon="arrow_right" size="1.125rem" />
        </component>
      </div>
    </slot>
  </nav>
</template>
<script>
import {
  SfIcon,
  SfLink,
  SfButton,
} from '@storefront-ui/vue'

export default {
  name: 'DsbPagination',
  components: {
    SfIcon,
    SfLink,
    SfButton,
  },
  props: {
    /**
     * Total number of pages
     */
    total: {
      type: Number,
      default: 0,
    },
    /**
     * Maximum visible pagination items
     */
    visible: {
      type: Number,
      default: 5,
    },
    /**
     * Status of arrows display
     */
    hasArrows: {
      type: Boolean,
      default: true,
    },
    /**
     * Current page number, for non router
     */
    current: {
      type: Number || undefined,
      required: true,
    },
    /**
     * Name of page query param for router
     */
    pageParamName: {
      type: String,
      default: 'page',
    },

    hideFirst: {
      type: Boolean,
      default: false,
    },

    hideLast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasRouter () {
      return this.$route
    },
    componentIs () {
      return this.hasRouter ? 'SfLink' : 'SfButton'
    },
    currentPage: {
      set (page) {
        if (parseInt(page) >= 0 && parseInt(this.$route.query[this.pageParamName]) !== parseInt(page)) {
          this.$router.push(this.getLinkTo(page))
        }
      },
      get () {
        return this.current
      },
    },
    getPrev () {
      return this.currentPage <= 1
        ? this.currentPage
        : this.currentPage - 1
    },
    canGoPrev () {
      return this.currentPage > 1
    },
    getNext () {
      return this.currentPage >= this.total
        ? this.currentPage
        : this.currentPage + 1
    },
    canGoNext () {
      return this.currentPage < this.total
    },
    showFirst () {
      return this.firstVisiblePageNumber > 1 && !this.hideFirst
    },
    showLast () {
      return this.lastVisiblePageNumber < this.total && !this.hideLast
    },
    listOfPageNumbers () {
      return Array.from(Array(this.total), (_, i) => i + 1)
    },
    limitedPageNumbers () {
      if (this.total <= this.visible) {
        return this.listOfPageNumbers
      }
      if (this.currentPage < this.visible - Math.floor(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(0, this.visible)
      }
      if (
        this.total - this.currentPage <
        this.visible - Math.ceil(this.visible / 2) + 1
      ) {
        return this.listOfPageNumbers.slice(this.total - this.visible)
      }
      return this.listOfPageNumbers.slice(
        this.currentPage - Math.ceil(this.visible / 2),
        this.currentPage + Math.floor(this.visible / 2),
      )
    },
    firstVisiblePageNumber () {
      return this.limitedPageNumbers[0]
    },
    lastVisiblePageNumber () {
      return this.limitedPageNumbers[this.limitedPageNumbers.length - 1]
    },
  },
  watch: {
    $route (route) {
      const queryValue = parseInt(route.query[this.pageParamName])
      if (this.currentPage !== queryValue) {
        this.currentPage = queryValue
      }
    },
  },
  created () {
    const pageParamValue = this.$route.query[this.pageParamName]
    if (pageParamValue) {
      this.currentPage = pageParamValue
    }
  },
  methods: {
    getLinkTo (page) {
      return {
        ...this.$route,
        query: { ...this.$route.query, [this.pageParamName]: page },
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/molecules/SfPagination.scss";
</style>
