








































import {PageCategory} from '~/types/PageCategory'
import ProductSuggestionActions from '~/modules/catalog/search/components/ProductSuggestionActions.vue'
import ProductDetails from "~/components/ProductDetails.vue";
import AddToCart from "~/components/AddToCart/component.vue";
import {computed} from "@nuxtjs/composition-api";
import {useProductDetails} from "~/composables";

export default {
  name: 'ProductSuggestion',
  components: {
    ProductDetails,
    ProductSuggestionActions,
    AddToCart
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    showAddToCart: {
      required: true,
      type: Boolean
    }
  },
  setup(props, {emit}) {
    const currentProduct = computed(() => {
      return props.product
    })
    const {isAddToCartVisible} = useProductDetails(currentProduct)

    function closeSearchResults() {
      emit('closeSearchResults')
    }

    const showAddToCartButton = computed(() => {
      return (props.showAddToCart && isAddToCartVisible.value)
    })

    return {
      PageCategory,
      currentProduct,
      showAddToCartButton,
      closeSearchResults,
    }
  },
}
