















































































































import {clickOutside} from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive.js'
import {SfSearchBar, SfIcon, SfButton, SfLoader} from '@storefront-ui/vue'
import ProductSuggestion from './ProductSuggestion.vue'
import {useSearch} from '~/composables'
import SearchResults from '~/modules/catalog/search/components/SearchResults.vue'
import { PageCategory } from "~/types/PageCategory";
import { General } from "~/types/search/SearchCategories";
import {watch, nextTick, defineComponent, computed} from "@nuxtjs/composition-api";
import Vue, { PropType } from "vue";
import { SearchBarId } from "~/modules/catalog/search/enum";


export default defineComponent ({
  name: 'SearchBar',
  directives: {
    clickOutside,
  },
  props: {
    id: {
      type: String as PropType<SearchBarId>,
      required: true
    }
  },
  components: {
    ProductSuggestion,
    SearchResults,
    SfSearchBar,
    SfIcon,
    SfButton,
    SfLoader
  },
  setup(props, {refs}) {
    const {
      isSearchOpen,
      isSearchExpanded,
      searchString,
      searchHistory,
      autocompleteResults,
      suggestions,
      clear,
      search,
      last,
      tempSearchInput,
      closeSearchResults,
      loading
    } = useSearch(props.id)

    function setInitialFocusOnInput() {
      if (isSearchExpanded.value && props.id === SearchBarId.HEADER) {
        const ref = 'searchBarRef'

        const inputElement = ((refs[ref] as Vue)?.$el || refs[ref]) as HTMLInputElement | Element

        nextTick(() => {
          if (inputElement.nodeName === 'INPUT') {
            (inputElement as HTMLInputElement).focus()
            return
          }

          inputElement.querySelector('input')?.focus()
        })
      }
    }

    watch(isSearchExpanded, () => setInitialFocusOnInput())

    return {
      PageCategory,
      General,
      isSearchOpen,
      isSearchExpanded,
      tempSearchInput,
      last,
      searchString,
      search,
      clear,
      autocompleteResults,
      searchHistory,
      suggestions,
      closeSearchResults,
      loading: computed(() => loading.value),
      SearchBarId
    }
  },
});
