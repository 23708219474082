





































































import {
  computed,
  ref,
  defineComponent,
  nextTick,
  useFetch,
  useRoute,
  useRouter,
} from "@nuxtjs/composition-api";
import { ListFilter } from "~/types/ListCategories";
import { PageCategory } from "~/types/PageCategory";
import { useProductList } from "~/composables";
import { SfLoader, SfCheckbox } from "@storefront-ui/vue";
import { useFilters } from "~/modules/catalog/category/components/filters/useFilters";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
import { findActiveCategory } from "~/modules/catalog/category/helpers/findActiveCategory";
import { usePageStore } from "~/stores/page";

export default defineComponent({
  name: "SearchCategorySidebar",
  components: {
    SfLoader,
    SfCheckbox,
    CategoryFilters: () => import('~/modules/catalog/category/components/filters/CategoryFilters.vue'),
  },
  props: {
    categories: {
      required: true,
      type: Object,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    newfilters: {
      required: true,
      type: Object,
    },
  },
  setup(props, { refs }) {
    const { routeData } = usePageStore();
    const route = useRoute();
    const router = useRouter();
    const { ...productListData } = useProductList();
    const { getSelectedFiltersFromUrl } = useFilters();
    const { categoryTree, isCategoryTreeLoaded, loadCategoryTree } =
      useTraverseCategory();

    useFetch(async () => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree();
      }
    });

    const getCategoryUidByName = (name) => {
      const category = findActiveCategory(categoryTree.value, name, "name");

      return category?.uid == null || category?.uid == undefined
        ? ""
        : category?.uid;
    };

    const filterByCategory = async (name) => {
      const categoryUid = getCategoryUidByName(name);

      router.replace({
        ...route,
        query: {
          term: route.value.query.term,
          category_uid: categoryUid,
        },
      });
    };

    const filterByUid = (uid) => {
      const filters = getSelectedFiltersFromUrl();
      router.replace({
        ...route,
        query: {
          term: route.value.query.term,
          category_uid: uid,
          ...filters
        },
      });
    };

    const searchCategoryTree = computed(() =>
      getSearchCategoryTree(categoryTree?.value?.children?.[0])
    );

    const getSearchCategoryTree = (categoryTreeNode) => {
      if (!categoryTreeNode) {
        return null;
      }

      if (props.categories.value) {
        const categories = props.categories.value
        const category = categories.find(
          (category) => category.label === categoryTreeNode.name
        );

        if (!category) {
          return null;
        }

        const node = {...categoryTreeNode, count: category.count, children: []};
        const childrenOriginal = categoryTreeNode.children || [];

        childrenOriginal.forEach((child) => {
          const nodeChild = getSearchCategoryTree(child);

          if (nodeChild !== null) {
            node.children.push(nodeChild);
          }
        });

        return node;
      }
    };

    const categoryListIndex = ref(0);
    const sideBarCategories = computed(() => searchCategoryTree.value);

    function moveFocus(catIndex, payload) {
      const itemsLength = sideBarCategories.value[catIndex].children.length;
      let index = categoryListIndex.value;

      index += payload;

      if (index < 0) {
        index = 0;
      }

      if (index >= itemsLength) {
        index = itemsLength - 1;
      }

      categoryListIndex.value = index;

      nextTick(() => {
        const categoryButton = refs[`category-${catIndex}-item`]?.[
          categoryListIndex.value
        ] as HTMLElement;

        if (categoryButton) {
          categoryButton.focus();
        }
      });
    }

    function setInitialFocusInCategoryList(categoryIndex) {
      nextTick(() => {
        const item = refs[`category-${categoryIndex}-item`]?.[0] as HTMLElement;
        item?.focus();
        categoryListIndex.value = 0;
      });
    }

    function tabBackFromCategoryList(tabToCategoryIndex) {
      if (tabToCategoryIndex < 0) {
        const showAllButton = document.querySelector(
          "#show-all-button-search-sidebar"
        ) as HTMLElement;
        showAllButton.focus();
        return;
      }

      setInitialFocusInCategoryList(tabToCategoryIndex);
    }

    const onReloadProducts = () => {};

    const onContentIsMounted = () => {};

    return {
      ...productListData,
      PageCategory,
      ListFilter,
      filterByCategory,
      searchCategoryTree,
      moveFocus,
      setInitialFocusInCategoryList,
      tabBackFromCategoryList,
      filterByUid,
      routeData,
      onReloadProducts,
      onContentIsMounted,
    };
  },
});
