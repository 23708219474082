var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sf-pagination",attrs:{"aria-label":"Seitennavigation"}},[(_vm.hasArrows)?_vm._t("prev",function(){return [_c('div',{staticClass:"sf-pagination__item prev"},[_c(_vm.componentIs,{tag:"component",class:{
          'sf-button--pure': !_vm.hasRouter,
          'sf-arrow--transparent': !_vm.hasRouter && !_vm.canGoPrev,
        },attrs:{"link":_vm.hasRouter && _vm.getLinkTo(_vm.getPrev),"disabled":!_vm.canGoPrev,"aria-label":"Go to previous page"},on:{"click":function($event){!_vm.hasRouter && (_vm.currentPage = _vm.getPrev)}}},[_c('SfIcon',{attrs:{"icon":"arrow_left","size":"1.125rem"}})],1)],1)]},null,{ isDisabled: !_vm.canGoPrev, getLinkTo: _vm.getLinkTo, prev: _vm.getPrev, goPrev: function () { return _vm.currentPage = _vm.getPrev; } }):_vm._e(),_vm._v(" "),(_vm.showFirst)?[_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter,
        },attrs:{"link":_vm.hasRouter && _vm.getLinkTo(1)},on:{"click":function($event){!_vm.hasRouter && (_vm.currentPage = 1)}}},[_vm._v("\n        1\n      ")])]},null,{ page: 1, getLinkTo: _vm.getLinkTo, goFirst: function () { return _vm.currentPage = 1; } }),_vm._v(" "),(_vm.firstVisiblePageNumber > 2)?_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item"},[_vm._v("\n        ...\n      ")])]}):_vm._e()]:_vm._e(),_vm._v(" "),_vm._l((_vm.limitedPageNumbers),function(page){return [_vm._t("number",function(){return [_c(_vm.currentPage === page ? 'span' : _vm.componentIs,{key:page,tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter && _vm.currentPage !== page,
          current: _vm.currentPage === page,
        },attrs:{"link":_vm.hasRouter && _vm.currentPage !== page && _vm.getLinkTo(page)},on:{"click":function($event){!_vm.hasRouter && _vm.currentPage !== page && (_vm.currentPage = page)}}},[_vm._v("\n        "+_vm._s(page)+"\n      ")])]},null,{ page: page, getLinkTo: _vm.getLinkTo, goPage: function () { return _vm.currentPage = page; } })]}),_vm._v(" "),(_vm.showLast)?[(_vm.lastVisiblePageNumber < _vm.total - 1)?_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item"},[_vm._v("\n        ...\n      ")])]}):_vm._e(),_vm._v(" "),_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter,
        },attrs:{"link":_vm.hasRouter && _vm.getLinkTo(_vm.total)},on:{"click":function($event){!_vm.hasRouter && (_vm.currentPage = _vm.total)}}},[_vm._v("\n        "+_vm._s(_vm.total)+"\n      ")])]},null,{ page: _vm.total, getLinkTo: _vm.getLinkTo, goLast: function () { return _vm.currentPage = _vm.total; } })]:_vm._e(),_vm._v(" "),(_vm.hasArrows)?_vm._t("next",function(){return [_c('div',{staticClass:"sf-pagination__item next"},[_c(_vm.componentIs,{tag:"component",class:{
          'sf-button--pure': !_vm.hasRouter,
          'sf-arrow--transparent': !_vm.hasRouter && !_vm.canGoNext,
        },attrs:{"link":_vm.hasRouter && _vm.getLinkTo(_vm.getNext),"disabled":!_vm.canGoNext,"aria-label":"Go to previous next"},on:{"click":function($event){!_vm.hasRouter && (_vm.currentPage = _vm.getNext)}}},[_c('SfIcon',{attrs:{"icon":"arrow_right","size":"1.125rem"}})],1)],1)]},null,{ isDisabled: !_vm.canGoNext, getLinkTo: _vm.getLinkTo, next: _vm.getNext, goNext: function () { return _vm.currentPage = _vm.getNext; } }):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }