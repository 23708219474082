








import {
  defineComponent, useContext, useFetch, ref, onMounted,
} from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import { useUiHelpers} from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { Breadcrumb } from '~/modules/catalog/types';
import {PageCategory} from "~/types/PageCategory";
import {General} from "~/types/Page";

export default defineComponent({
  components: { SfBreadcrumbs },
  setup() {
    const { getCatLink } = useUiHelpers();
    const { app: { i18n }, localePath } = useContext();
    const {
      categoryAncestors, isCategoryTreeLoaded, loadCategoryTree, activeCategory
    } = useTraverseCategory();
    const breadcrumbs = ref<Breadcrumb[]>([]);

    let breadcrumbList:Breadcrumb[] =  [
      {
        text: i18n.t(`${PageCategory.HOMEPAGE}.${General.TITLE}`).toString(),
        link: '/',
      }
    ];

    useFetch(async () => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree();
      }
    });

    onMounted(async () => {
      const breadcrumbItems = categoryAncestors.value.slice(0, -1).map((category) => ({
        text: category.name,
        link: localePath(getCatLink(category)),
      }));

      breadcrumbList.push(...breadcrumbItems)

      if(activeCategory.value !== null){
        breadcrumbList.push({
          text: activeCategory.value.name,
          link: '#',
        })
      }

      breadcrumbs.value = breadcrumbList
    });

    return {
      breadcrumbs,
    };
  },
});
