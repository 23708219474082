















import DsbBreadcrumbs from '~/components/Breadcrumbs/DsbBreadcrumbs.vue'
import { usePage } from '~/composables'
import {computed, defineComponent} from '@nuxtjs/composition-api'
import { getInstance } from '~/helpers/hooks/getInstance'
import { PageCategory } from '~/types/PageCategory'
import { General } from '~/types/Page'

export default defineComponent({
  name: 'PageHeader',
  components: { DsbBreadcrumbs },

  setup() {
    const vm = getInstance();
    const { pageTitle, breadcrumbs } = usePage()

    breadcrumbs.value = computed(() => [
      {
        text: vm.proxy.$i18n.t(`${PageCategory.HOMEPAGE}.${General.TITLE}`).toString(),
        link: "/"
      },
      {
        text: pageTitle.value,
        link: ""
      }
    ])

    return {
      pageTitle,
      breadcrumbs,
    }
  },
})
