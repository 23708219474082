














































































































import { PageCategory } from '~/types/PageCategory'
import ProductActions from '~/components/ProductActions/ProductActions.vue'
import { SearchListItem } from '~/types/search/SearchCategories'
import ProductDetails from '~/components/ProductDetails.vue'
import { useAddToCart } from "~/composables/useAddToCart";
import AddToCart from "~/components/AddToCart/component.vue";
import AddToCartAction from "~/components/ProductActions/AddToCartAction.vue";
import PersonalizeAction from "~/components/ProductActions/PersonalizeAction.vue";
import DownloadAction from "~/components/ProductActions/DownloadAction.vue";
import FavoriteAction from "~/components/ProductActions/FavoriteAction.vue";
import { ActionType } from "~/types/Action";
import { useCart, useProductActions, useErrorMessage } from "~/composables";
import { ProductInterface } from "~/modules/GraphQL/types";
import ErrorMessage from "~/components/ProductActions/ErrorMessage.vue";
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives/focus-trap/focus-trap-directive.js'
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive.js'
import { computed, defineComponent, onMounted, onBeforeUnmount, PropType } from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'SearchListItem',
  components: {
    ErrorMessage,
    DownloadAction,
    AddToCartAction,
    PersonalizeAction,
    FavoriteAction,
    AddToCart,
    ProductDetails,
    ProductActions,
  },
  directives: {
    focusTrap,
    clickOutside
  },
  props: {
    sku: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    imgSrc: {
      required: true,
      type: String,
    },
    details: {
      required: true,
      type: Array,
    },
    actions: {
      required: true,
      type: Array,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object as PropType<ProductInterface>
    }
  },
  setup(props) {
    const product = computed(() => props.product);
    const {actions} = useProductActions(props.product)
    const {error, lastUpdatedCartSku} = useCart()
    const {resetAllErrors} = useErrorMessage(props.sku)

    function getAction(type: ActionType) {
      return actions.value.get(type)
    }

    onMounted(() => {
      resetAllErrors()
    })

    return {
      ...useAddToCart(props.sku, product),
      error,
      lastUpdatedCartSku,
      getAction,
      ActionType,
      PageCategory,
      SearchListItem,
    }
  },
})
