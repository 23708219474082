












































































import CategoryBreadcrumbs from '~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue';

import {
  SfRadio
} from '@storefront-ui/vue';
import { PageCategory } from "~/types/PageCategory";
import { ListHeader } from "~/types/ListCategories";

import { ListView } from "~/types/ListView";
import { usePage } from "~/composables";
import { useUiHelpers, useUiState } from "~/composables";
import {defineComponent, onMounted} from "@nuxtjs/composition-api";
import DsbPageHeaderSelect from "~/components/Select/DsbPageHeaderSelect.vue";
import {useI18n} from "~/helpers/hooks/usei18n";
import {General} from "~/types/Page";
import DsbBreadcrumbs from "~/components/Breadcrumbs/DsbBreadcrumbs.vue";
import {useProductListView} from "~/composables/useProductListView";

export default defineComponent ({
  name: "DsbProductListHeader",
  components: {
    DsbPageHeaderSelect,
    CategoryBreadcrumbs,
    SfRadio,
    DsbBreadcrumbs
  },
  props: {
    pagination: {
      required: true,
      type: Object
    },
    sortBy: {
      type: Object
    },
    activeView: {
      required: true,
      type: String
    }
  },
  setup(prop) {
    const { toggleFilterSidebar } = useUiState();
    const { changeSorting } = useUiHelpers();

    const { pageTitle }= usePage()
    const i18n = useI18n();

    const {
      view,
      initViewSelection
    } = useProductListView();

    function getTotalItemsText(totalItems){
      if(pageTitle.value === 'Katalog') {
        return totalItems === 1?
          i18n.t(`${PageCategory.LIST}.${ListHeader.ONE_PRODUCT_QTY}`)
          : i18n.t(`${PageCategory.LIST}.${ListHeader.PRODUCTS_QTY}`, {products: totalItems})
      }

      return totalItems === 1?
        i18n.t(`${PageCategory.LIST}.${ListHeader.ONE_PRODUCT_FOUND}`)
        : i18n.t(`${PageCategory.LIST}.${ListHeader.PRODUCTS_FOUND}`, {products: totalItems})
    }

    function getSortByScreenReaderText(totalItems){
      if(pageTitle.value === 'Katalog') {
        return totalItems === 1?
          i18n.t(`${PageCategory.LIST}.${ListHeader.ONE_PRODUCT_QTY_SR}`) + ' im Katalog ' + i18n.t(`${PageCategory.LIST}.${ListHeader.SORT_BY}`)
          : i18n.t(`${PageCategory.LIST}.${ListHeader.PRODUCTS_QTY}`, {products: totalItems}) + ' im Katalog ' + i18n.t(`${PageCategory.LIST}.${ListHeader.SORT_BY}`)
      }

      return totalItems === 1?
        i18n.t(`${PageCategory.LIST}.${ListHeader.ONE_PRODUCT_FOUND_SR}`) + ' ' + i18n.t(`${PageCategory.LIST}.${ListHeader.SORT_BY}`)
        : i18n.t(`${PageCategory.LIST}.${ListHeader.PRODUCTS_FOUND}`, {products: totalItems}) + ' ' + i18n.t(`${PageCategory.LIST}.${ListHeader.SORT_BY}`)
    }

    let searchBreadcrumbList = [
      {
        text: i18n.t(`${PageCategory.HOMEPAGE}.${General.TITLE}`).toString(),
        link: '/',
      },
      {
        text:i18n.t(`${PageCategory.SEARCH}.${General.TITLE}`).toString(),
        link: '/search',
      },
    ]

    onMounted(async () => {
      if (prop.activeView != ListView.SEARCH) {
        view.value = prop.activeView;
      }
    })

    return {
      ...usePage(),
      pageTitle,
      toggleFilterSidebar,
      changeSorting,
      ListView,
      PageCategory,
      ListHeader,
      getTotalItemsText,
      getSortByScreenReaderText,
      searchBreadcrumbList,
      view
    }
  }
})
