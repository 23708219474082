import { computed, customRef } from "@nuxtjs/composition-api";
import {useProductDetails} from "~/composables";

const items = {}

export function useAddToCart(sku, currentProduct) {

  const addToCartOpen = customRef<boolean>((track, trigger) => ({
    set(value) {
      items[sku] = value
      trigger()
    },
    get() {
      track()
      return !!items[sku]
    }
  }))

  const { isAddToCartVisible, isDownloadable, isPersonalisable } = useProductDetails(currentProduct)

  return {
    isAddToCartVisible,
    isDownloadable,
    isPersonalisable,
    addToCartOpen,
    currentProduct
  }
}
